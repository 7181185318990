import React from 'react';
import {IStage, IStageActorInstance} from '../../Interfaces/stage.interface';
import './GamePrompt.scss';
import GamePromptPlaceholder from '../GamePromptPlaceholder/GamePromptPlaceholder';

export interface IGamePromptProps {
    selectedStage: IStage;
    answer: Record<number, number>;
    onAnswerPlaced: (actorInstance: IStageActorInstance, promptIndex: number) => void;
}

function GamePrompt({selectedStage, answer, onAnswerPlaced}: IGamePromptProps) {
    return (
        <div className='gamePrompt'>
            {
                selectedStage.prompt.split('_').map((part, index) => {
                    return (
                        <div
                            key={`${selectedStage.id}_${index}`}
                            className='gamePromptPart'
                        >
                            <div>{part}</div>
                            {
                                index < selectedStage.prompt.split('_').length - 1 &&
                                <GamePromptPlaceholder
                                    placeholderIndex={index}
                                    selectedStage={selectedStage}
                                    answer={answer}
                                    onAnswerPlaced={(actorInstance) => onAnswerPlaced(actorInstance, index)}
                                />
                            }
                        </div>
                    );
                })
            }
        </div>
    );
}

export default GamePrompt;
