import {Button, TextField} from '@mui/material';
import React, {useState} from 'react';
import './GameNameSelector.scss';

export interface IGameNameSelectorProps {
    onNameSelected: (name: string) => void;
}

function GameNameSelector({onNameSelected}: IGameNameSelectorProps) {
    const [username, setUsername] = useState('');

    return (
        <div className='gameNameSelector'>
            <TextField
                label={'Votre nom'}
                variant={'standard'}
                autoComplete='off'
                value={username}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setUsername(event.target.value);
                }}
            />

            <Button
                variant='outlined'
                sx={{textTransform: 'none'}}
                onClick={() => {
                    if (username) {
                        onNameSelected(username);
                    }
                }}
                disabled={!username}
            >
                Commencer
            </Button>
        </div>
    );
}

export default GameNameSelector;
