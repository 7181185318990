import React, {useCallback} from 'react';
import './GameActor.scss';
import {IStageActorInstance} from '../../Interfaces/stage.interface';
import {useDrag} from 'react-dnd';

export interface IGameActorProps {
    actorInstance: IStageActorInstance;
}

const previewOptions = {
    offsetX: 0,
    offsetY: 0
};

function GameActor({actorInstance}: IGameActorProps) {
    const [, drag, preview] = useDrag(() => ({
        type: 'GameActor',
        item: actorInstance,
        previewOptions
    }));

    const callbackRef = useCallback((node: any) => {
        drag(node);
        preview(node, previewOptions);
    }, [drag, preview]);

    return (
        <div
            ref={callbackRef}
            className='gameActor'
        >
            <img src={actorInstance.actor.imageUrl} alt='actor' />
        </div>
    );
}

export default GameActor;
