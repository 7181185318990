import React, {ReactNode, useCallback, useContext, useEffect, useState} from 'react';
import './GameStats.scss';
import {AppDataContext} from '../../Providers/AppDataContext';
import {IGameStats} from '../../Interfaces/app-data.interface';
import {Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';

function GameStats() {
    const {getStats} = useContext(AppDataContext);

    const [stats, setStats] = useState<IGameStats[]>();

    const exportCsv = useCallback(() => {
        const items = stats?.reduce((acc, row) => {
            acc.push(...row.data.map((data) => {
                return {
                    username: row.username,
                    dateTime: row.dateTime,
                    stageIndex: data.stageIndex,
                    stageName: data.stageName,
                    stageType: data.stageType,
                    answer: JSON.stringify(data.answer),
                    expectedAnswer: JSON.stringify(data.expectedAnswer),
                    numberOfTries: data.numberOfTries
                };
            }));

            return acc;
        }, [] as any);

        const replacer = (key: string, value: any) => value === null ? '' : value;
        const header = Object.keys(items[0]);
        const csv = [
            header.join(','),
            ...items.map((row: any) => header.map(fieldName => JSON.stringify(row[fieldName], replacer).replace(/\\"/g, '""')).join(','))
        ].join('\r\n');

        const blob = new Blob([csv], {type: 'text/csv'});
        const elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob);
        elem.download = 'Stats.csv';
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
    }, [stats]);

    useEffect(() => {
        const fetchStats = async () => {
            const stats = await getStats();
            setStats(stats);
        };

        fetchStats();
    }, [setStats]);

    return (
        <div className='gameStats'>
            <Button
                variant='outlined'
                sx={{textTransform: 'none'}}
                onClick={exportCsv}
            >
                Exporter
            </Button>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>StageIndex</TableCell>
                            <TableCell>StageName</TableCell>
                            <TableCell>StageType</TableCell>
                            <TableCell>Answer</TableCell>
                            <TableCell>ExpectedAnswer</TableCell>
                            <TableCell>NumberOfTries</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            stats?.reduce((acc, row, rowIndex) => {
                                acc.push(...row.data.map((data, dataIndex) => {
                                    return (
                                        <TableRow
                                            key={`${rowIndex}_${dataIndex}`}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.username}
                                            </TableCell>
                                            <TableCell>{row.dateTime}</TableCell>
                                            <TableCell>{data.stageIndex}</TableCell>
                                            <TableCell>{data.stageName}</TableCell>
                                            <TableCell>{data.stageType}</TableCell>
                                            <TableCell>{JSON.stringify(data.answer)}</TableCell>
                                            <TableCell>{JSON.stringify(data.expectedAnswer)}</TableCell>
                                            <TableCell>{data.numberOfTries}</TableCell>
                                        </TableRow>
                                    );
                                }));

                                return acc;
                            }, [] as ReactNode[])
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default GameStats;
