import React, {useCallback, useContext, useReducer} from 'react';
import {IStage, StageType} from '../../Interfaces/stage.interface';
import './StageEditor.scss';
import StageActorsList from '../StageActorsList/StageActorsList';
import StagePromptEditor from '../StagePromptEditor/StagePromptEditor';
import {Checkbox, TextField} from '@mui/material';
import StagePreview from '../StagePreview/StagePreview';
import {AppDataContext} from '../../Providers/AppDataContext';
import {useDebouncedCallback} from 'use-debounce';
import StageHiddenActorsList from '../StageHiddenActorsList/StageHiddenActorsList';

export interface IStageEditorProps {
    selectedStage?: IStage;
}

function StageEditor({selectedStage}: IStageEditorProps) {
    const {saveAppData} = useContext(AppDataContext);
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    const saveDataDebounced = useDebouncedCallback(useCallback(() => {
        saveAppData();
    }, [saveAppData]), 300);

    const setPrompt = useCallback((text: string) => {
        if (selectedStage) {
            selectedStage.prompt = text;
            forceUpdate();
            saveDataDebounced();
        }
    }, [selectedStage]);

    const switchStageType = useCallback(() => {
        if (selectedStage) {
            selectedStage.type = selectedStage.type === StageType.Choices ? StageType.FillInTheBlanks : StageType.Choices;
            forceUpdate();

            saveAppData();
        }
    }, [selectedStage, selectedStage?.type]);

    const setChoice = useCallback((text: string, choiceField: string) => {
        if (selectedStage) {
            (selectedStage as any)[choiceField] = text;
            forceUpdate();

            saveDataDebounced();
        }
    }, [selectedStage]);

    const setRightChoiceIndex = useCallback((index: number) => {
        if (selectedStage) {
            (selectedStage as any)[`choice${index}Checked`] = !(selectedStage as any)[`choice${index}Checked`];
            forceUpdate();

            saveAppData();
        }
    }, [selectedStage]);

    return (
        <div className='stageEditor'>
            <div className='stageContent'>
                <div className='stageContentScene'>
                    {
                        selectedStage &&
                        <div className='stageTitle'>
                            <div>{selectedStage?.name}</div>

                            <img
                                onClick={switchStageType}
                                src={selectedStage.type === StageType.FillInTheBlanks ? '/images/fill_in_the_blanks.png' : '/images/bullet_list.png'}
                            />
                        </div>
                    }

                    <div className='stageContentPreviewContainer'>
                        <div className='stageContentPreview'>
                            {
                                !selectedStage &&
                                <div className='noSelectedStage'>
                                    No Selected stage
                                </div>
                            }

                            {
                                selectedStage &&
                                <StagePreview
                                    selectedStage={selectedStage}
                                />
                            }
                        </div>

                        {
                            selectedStage?.type === StageType.FillInTheBlanks &&
                            <StageHiddenActorsList selectedStage={selectedStage} />
                        }
                    </div>

                    {
                        selectedStage?.type === StageType.FillInTheBlanks &&
                        <div className='stageContentPromptContainer'>
                            {
                                selectedStage &&
                                <TextField
                                    label={'Prompt'}
                                    variant={'standard'}
                                    autoComplete='off'
                                    value={selectedStage?.prompt}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setPrompt(event.target.value);
                                    }}
                                />
                            }
                        </div>
                    }

                    {
                        selectedStage?.type === StageType.Choices &&
                        <div className='stageContentChoicesContainer'>
                            {
                                Array.from(Array(3).keys()).map(index => {
                                    return (
                                        <div
                                            key={`${selectedStage.id}_choice${index}`}
                                            className='stageContentChoice'
                                        >
                                            <Checkbox
                                                checked={(selectedStage as any)[`choice${index}Checked`]}
                                                onChange={() => setRightChoiceIndex(index)}
                                            />

                                            <div className='stageContentChoiceTextFields'>
                                                <TextField
                                                    label={`Choice ${index + 1}`}
                                                    variant={'standard'}
                                                    autoComplete='off'
                                                    value={(selectedStage as any)[`choice${index}`]}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        setChoice(event.target.value, `choice${index}`);
                                                    }}
                                                />

                                                <TextField
                                                    label={`Choice ${index + 1} hint`}
                                                    variant={'standard'}
                                                    autoComplete='off'
                                                    value={(selectedStage as any)[`choice${index}Hint`]}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        setChoice(event.target.value, `choice${index}Hint`);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    }
                </div>

                {
                    selectedStage?.type === StageType.FillInTheBlanks &&
                    <div className='stagePromptEditorContainer'>
                        {
                            selectedStage &&
                            <StagePromptEditor
                                selectedStage={selectedStage}
                            />
                        }
                    </div>
                }
            </div>

            <div className='stageActorsListContainer'>
                <StageActorsList />
            </div>
        </div>
    );
}

export default StageEditor;
