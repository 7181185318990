import React, {useCallback, useContext, useState} from 'react';
import {IStage, StageType} from '../../Interfaces/stage.interface';
import './StagesList.scss';
import StageListItem from '../StageListItem/StageListItem';
import StageListNewItem from '../StageListNewItem/StageListNewItem';
import {AppDataContext} from '../../Providers/AppDataContext';
import PerfectScrollbar from 'react-perfect-scrollbar';

export interface IStagesListProps {
    selectedStage?: IStage;
    onStageSelected: (stage: IStage) => void;
}

function StagesList({selectedStage, onStageSelected}: IStagesListProps) {
    const {appData: {stages}, setStages} = useContext(AppDataContext);

    const onAddNewStage = useCallback(() => {
        const newStage: IStage = {
            id: crypto.randomUUID(),
            name: `Stage ${(stages || []).length + 1}`,
            type: StageType.FillInTheBlanks,
            prompt: '',
            promptPlaceholders: {},
            choice0: '',
            choice0Hint: '',
            choice0Checked: false,
            choice1: '',
            choice1Hint: '',
            choice1Checked: false,
            choice2: '',
            choice2Hint: '',
            choice2Checked: false,
        };

        setStages([...(stages || []), newStage]);

        onStageSelected(newStage);
    }, [stages, setStages, onStageSelected]);

    return (
        <div className='stagesList'>
            <PerfectScrollbar>
                {
                    stages?.map((stage, index) => {
                        return <StageListItem
                            key={index}
                            stage={stage}
                            isSelected={!selectedStage || stage === selectedStage}
                            onSelectStage={() => onStageSelected(stage)}
                        />;
                    })
                }

                <StageListNewItem
                    onAddNewStage={onAddNewStage}
                />
            </PerfectScrollbar>
        </div>
    );
}

export default StagesList;
