import React, {ReactNode, useCallback, useContext, useEffect, useState} from 'react';
import './Game.scss';
import {IStage, IStageActorInstance, StageType} from '../../Interfaces/stage.interface';
import StageActorInstance from '../StageActorInstance/StageActorInstance';
import GamePrompt from '../GamePrompt/GamePrompt';
import GameActor from '../GameActor/GameActor';
import GameNameSelector from '../GameNameSelector/GameNameSelector';
import {AppDataContext} from '../../Providers/AppDataContext';
import {Button} from '@mui/material';
import {isEqual} from 'lodash';
import {IGameStats} from '../../Interfaces/app-data.interface';

function Game() {
    const {appData: {stages}, saveStats} = useContext(AppDataContext);

    const [username, setUsername] = useState('');
    const [showInstructions, setShowInstructions] = useState(true);
    const [currentStage, setCurrentStage] = useState<IStage>();
    const [answer, setAnswer] = useState<Record<number, number>>({});
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [showChoiceHint, setShowChoiceHint] = useState({choice0: false, choice1: false, choice2: false});
    const [allDone, setAllDone] = useState(false);
    const [stats, setStats] = useState<Record<number, Record<number, number>[] | {choice0: boolean, choice1: boolean, choice2: boolean}>>({});
    const [correctAnswer, setCorrectAnswer] = useState<boolean|undefined>(undefined);
    const [answerHistory, setAnswerHistory] = useState<Record<number, number>[]>([]);

    const onGameActorPlaced = useCallback((actorInstance: IStageActorInstance, promptIndex: number) => {
        const newAnswer: Record<number, number> = {
            ...answer,
            [promptIndex]: currentStage?.actorInstances?.indexOf(actorInstance)!
        };

        setAnswer(newAnswer);
        setHasSubmitted(false);
    }, [currentStage, answer, setAnswer, setHasSubmitted]);

    const submitAnswer = useCallback(() => {
        setHasSubmitted(true);
        setCorrectAnswer(isEqual(answer, currentStage?.promptPlaceholders));
        setAnswerHistory([...answerHistory, answer]);

        const newAnswer = {...answer};
        Object.keys(newAnswer).forEach(key => {
            if (newAnswer[Number.parseInt(key)] !== currentStage?.promptPlaceholders[Number.parseInt(key)]) {
                delete newAnswer[Number.parseInt(key)];
            }
        });

        setAnswer(newAnswer);
    }, [setHasSubmitted, answer, currentStage?.promptPlaceholders, setCorrectAnswer, setAnswer, answerHistory, setAnswerHistory]);

    const goNext = useCallback(() => {
        if (currentStage) {
            const currentIndex = stages?.indexOf(currentStage);

            if (currentIndex != null) {
                const newStats: Record<number, Record<number, number>[] | {choice0: boolean, choice1: boolean, choice2: boolean}> = {
                    ...stats,
                    [currentIndex]: currentStage.type === StageType.Choices ? {...showChoiceHint} : answerHistory
                };
                setStats(newStats);

                if (currentIndex < stages!.length - 1) {
                    setCurrentStage(stages![currentIndex + 1]);
                    setAnswer({});
                    setHasSubmitted(false);
                    setShowChoiceHint({choice0: false, choice1: false, choice2: false});
                    setCorrectAnswer(undefined);
                    setAnswerHistory([]);
                } else {
                    setAllDone(true);

                    const stats: IGameStats = {
                        username,
                        dateTime: (new Date()).toUTCString(),
                        data: Object.keys(newStats).map(index => {
                            const stageIndex = Number.parseInt(index);
                            const stage = stages?.[stageIndex]!;

                            return {
                                stageIndex: Number.parseInt(index),
                                stageName: stage.name,
                                stageType: stage.type,
                                answer: {...newStats[stageIndex]},
                                expectedAnswer: stage.type === StageType.Choices ?
                                    {
                                        choice0: stage.choice0Checked,
                                        choice1: stage.choice1Checked,
                                        choice2: stage.choice2Checked
                                    } :
                                    {...stage.promptPlaceholders},
                                numberOfTries: stage.type === StageType.FillInTheBlanks ? (newStats[stageIndex] as any).length : undefined
                            };
                        })
                    };

                    saveStats(stats);
                }
            }
        }
    }, [setAnswer, setHasSubmitted, setCurrentStage, currentStage, stages, setAllDone, stats, setStats, showChoiceHint, answer, setCorrectAnswer, answerHistory]);

    useEffect(() => {
        setCurrentStage(stages?.[0]);
    }, [setCurrentStage, stages]);

    return (
        <div className='game'>
            {
                !username &&
                <GameNameSelector
                    onNameSelected={userName => setUsername(userName)}
                />
            }

            {
                username && showInstructions &&
                <div className='gameInstructions'>
                    <div className='gameInstructionsText'>
                        Suis les instructions en déplaçant les cartes du bas dans les carrés gris.
                    </div>

                    <img src={'demo.gif'} />

                    <Button
                        variant='outlined'
                        sx={{textTransform: 'none'}}
                        onClick={() => setShowInstructions(false)}
                    >
                        C&apos;est parti!
                    </Button>
                </div>
            }

            {
                allDone &&
                <div className='gameAllDone'>
                    Merci d&apos;avoir joué!
                </div>
            }

            {
                username &&
                !showInstructions &&
                currentStage &&
                !allDone &&
                <>
                    <div className='gameUsername'>
                        {username}
                    </div>

                    <div className='gamePreviewContainer'>
                        <div className='gamePreviewImage'>
                            <img
                                src={currentStage?.backgroundUrl}
                                alt='preview'
                            />

                            {
                                currentStage.actorInstances?.filter(actorInstance => !actorInstance.hidden).map((actorInstance, index) => {
                                    return (
                                        <StageActorInstance
                                            key={`${currentStage.id}_${index}`}
                                            actorInstance={actorInstance}
                                            disableDrag={true}
                                            answerPosition={
                                                hasSubmitted && Object.values(answer).includes(index) ?
                                                    currentStage.actorInstances?.[currentStage.promptPlaceholders[Object.values(answer).indexOf(index)]].position :
                                                    undefined
                                            }
                                        />
                                    );
                                })
                            }

                            {
                                hasSubmitted &&
                                <div className='gameStageResult'>
                                    <img
                                        src={
                                            isEqual(answer, currentStage?.promptPlaceholders) ?
                                                '/images/checked.png' :
                                                '/images/cancel.png'
                                        }
                                    />
                                </div>
                            }
                        </div>
                    </div>

                    {
                        currentStage.type === StageType.FillInTheBlanks &&
                        <>
                            <div className='gamePromptContainer'>
                                <GamePrompt
                                    selectedStage={currentStage}
                                    answer={answer}
                                    onAnswerPlaced={(actorInstance, promptIndex) => onGameActorPlaced(actorInstance, promptIndex)}
                                />

                                <div className='gamePromptButtons'>
                                    <Button
                                        variant='outlined'
                                        sx={{textTransform: 'none'}}
                                        onClick={submitAnswer}
                                        disabled={hasSubmitted || Object.keys(answer).length !== currentStage.prompt.split('_').length - 1}
                                    >
                                        Soumettre
                                    </Button>

                                    <Button
                                        variant='outlined'
                                        sx={{textTransform: 'none'}}
                                        onClick={goNext}
                                        disabled={!hasSubmitted || !correctAnswer}
                                    >
                                        Suivant
                                    </Button>
                                </div>
                            </div>

                            <div className='gameActorsContainer'>
                                {
                                    currentStage.actorInstances?.reduce((acc, actorInstance, index) => {
                                        if (Object.values(currentStage.promptPlaceholders).includes(index)) {
                                            acc.push(
                                                <GameActor
                                                    key={`${currentStage.id}_${index}`}
                                                    actorInstance={actorInstance}
                                                />
                                            );
                                        }

                                        return acc;
                                    }, [] as ReactNode[])
                                }
                            </div>
                        </>
                    }

                    {
                        currentStage.type === StageType.Choices &&
                        <div className='gameChoicesContainer'>
                            <div>
                                {
                                    Array.from(Array(3).keys()).map(index => {
                                        return (
                                            <div
                                                key={`${currentStage.id}_choice${index}`}
                                                className='gameChoice'
                                            >
                                                <Button
                                                    variant='outlined'
                                                    sx={{textTransform: 'none'}}
                                                    onClick={() => {
                                                        setShowChoiceHint({
                                                            ...showChoiceHint,
                                                            [`choice${index}`]: true
                                                        });
                                                    }}
                                                >
                                                    {(currentStage as any)[`choice${index}`]}
                                                </Button>

                                                {
                                                    (showChoiceHint as any)[`choice${index}`] &&
                                                    <div className={`gameChoiceHint--${(currentStage as any)[`choice${index}Checked`] ? 'success' : 'failure'}`}>
                                                        {(currentStage as any)[`choice${index}Hint`]}
                                                    </div>
                                                }
                                            </div>
                                        );
                                    })
                                }

                                <Button
                                    variant='outlined'
                                    sx={{textTransform: 'none'}}
                                    onClick={goNext}
                                    disabled={!Array.from(Array(3).keys()).some(choiceIndex => (showChoiceHint as any)[`choice${choiceIndex}`] && (currentStage as any)[`choice${choiceIndex}Checked`])}
                                >
                                    Suivant
                                </Button>
                            </div>
                        </div>
                    }
                </>
            }
        </div>
    );
}

export default Game;
