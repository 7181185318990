import React from 'react';
import './StageActorNewListItem.scss';
import '../StageActorListItem/StageActorListItem.scss';

export interface IStageActorNewListItemProps {
    onAddNewActor: () => void;
}

function StageActorNewListItem({onAddNewActor}: IStageActorNewListItemProps) {
    return (
        <div
            className='stageActorListItem stageActorNewListItem'
            onClick={onAddNewActor}
        >
            +
        </div>
    );
}

export default StageActorNewListItem;
