import React, {useCallback, useContext, useReducer, useRef} from 'react';
import {IStage, IStageActor, IStageActorInstance} from '../../Interfaces/stage.interface';
import './StagePreview.scss';
import {FileContent, SelectedFiles, useFilePicker} from 'use-file-picker';
import {useDrop} from 'react-dnd';
import StageActorInstance from '../StageActorInstance/StageActorInstance';
import {AppDataContext} from '../../Providers/AppDataContext';

export interface IStagePreviewProps {
    selectedStage: IStage;
}

function StagePreview({selectedStage}: IStagePreviewProps) {
    const {saveAppData, uploadImage} = useContext(AppDataContext);

    const [, forceUpdate] = useReducer(x => x + 1, 0);

    const containerRef = useRef<HTMLDivElement | null>(null);

    const setBackgroundImage = useCallback(async (file: File) => {
        const imageFileName = await uploadImage(selectedStage.id, file);
        selectedStage.backgroundUrl = `/learner/upload/${imageFileName}`;

        saveAppData();
        forceUpdate();
    }, [selectedStage, uploadImage]);

    const addActorToStage = useCallback((actor: IStageActor, position: {x: number; y: number}) => {
        if (!selectedStage.actorInstances) {
            selectedStage.actorInstances = [];
        }

        selectedStage.actorInstances = [
            ...selectedStage.actorInstances,
            {
                id: crypto.randomUUID(),
                actor,
                position
            }
        ];

        forceUpdate();
        saveAppData();
    }, [selectedStage]);

    const removeActorInstance = useCallback((actorInstance: IStageActorInstance) => {
        const index = selectedStage.actorInstances?.indexOf(actorInstance);
        if (index != null && index >= 0) {
            selectedStage.actorInstances?.splice(index, 1);
            forceUpdate();
            saveAppData();
        }
    }, [selectedStage]);

    const [openFileSelector] = useFilePicker({
        accept: 'image/*',
        multiple: false,
        onFilesSelected: (selectedFiles: SelectedFiles) => {
            setBackgroundImage(selectedFiles.plainFiles[0]);
        }
    });

    const [, drop] = useDrop(() => ({
        accept: ['Actor', 'ActorInstance'],
        drop: (item: IStageActor|IStageActorInstance, monitor) => {
            const offset = monitor.getSourceClientOffset();
            if (offset && containerRef.current) {
                const dropTargetXy = containerRef.current.getBoundingClientRect();
                const position = {
                    x: Math.round((offset.x - dropTargetXy.left)),
                    y: Math.round((offset.y - dropTargetXy.top))
                };

                const initialClientOffset = monitor.getInitialClientOffset();
                const initialSourceClientOffset = monitor.getInitialSourceClientOffset();

                if (initialClientOffset && initialSourceClientOffset) {
                    const initialOffset = {
                        x: Math.round(initialClientOffset.x - initialSourceClientOffset.x),
                        y: Math.round(initialClientOffset.y - initialSourceClientOffset.y)
                    };

                    position.x += initialOffset.x;
                    position.y += initialOffset.y;
                }

                const itemType = monitor.getItemType();
                if (itemType === 'Actor') {
                    addActorToStage(item as IStageActor, position);
                } else if (itemType === 'ActorInstance') {
                    (item as IStageActorInstance).position = position;
                    forceUpdate();
                    saveAppData();
                }
            }
        }
    }), [selectedStage]);

    return (
        <div
            ref={containerRef}
            className='stagePreviewContainer'
        >
            <div
                ref={drop}
                className='stagePreview'
            >
                <img
                    className={`stagePreviewSetBackground ${selectedStage.backgroundUrl ? 'stagePreviewSetBackground--small' : ''}`}
                    src={'/images/image.png'}
                    alt='set background'
                    onClick={openFileSelector}
                />

                {
                    selectedStage.backgroundUrl &&
                    <img
                        className='stagePreviewBackgroundImage'
                        src={selectedStage.backgroundUrl}
                        alt='background'
                    />
                }

                {
                    selectedStage.actorInstances?.filter(actorInstance => !actorInstance.hidden).map((actorInstance, index) => {
                        return <StageActorInstance
                            key={`${selectedStage.id}_${actorInstance.id}`}
                            actorInstance={actorInstance}
                            onRemoveInstance={() => removeActorInstance(actorInstance)}
                        />;
                    })
                }
            </div>
        </div>
    );
}

export default StagePreview;
