import React, {ReactNode, useCallback, useEffect} from 'react';
import {createContext, useState} from 'react';
import {IAppData, IGameStats} from '../Interfaces/app-data.interface';
import {IStage, IStageActor} from '../Interfaces/stage.interface';

interface IAppDataContext {
    appData: IAppData;
    setStages: (stages: IStage[]) => void;
    setActors: (actors: IStageActor[]) => void;
    saveAppData: () => void;
    uploadImage: (imageId: string, file: File) => Promise<string>;
    saveStats: (data: IGameStats) => void;
    getStats: () => Promise<IGameStats[]>;
}

export const AppDataContext = createContext<IAppDataContext>({} as any);

const AppDataProvider = ({children}: {children: ReactNode}) => {
    const [appData, setAppData] = useState<IAppData>({});

    const saveAppData = useCallback(async (appData: IAppData) => {
        await fetch('/learner/appdata', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(appData)
        });
    }, []);

    const setStages = useCallback((stages: IStage[]) => {
        const newAppData = {
            stages: [...stages],
            actors: appData.actors ? [...appData.actors] : undefined
        };

        setAppData(newAppData);
        saveAppData(newAppData);
    }, [appData, setAppData]);

    const setActors = useCallback((actors: IStageActor[]) => {
        const newAppData = {
            stages: appData.stages ? [...appData.stages] : undefined,
            actors: [...actors]
        };

        setAppData(newAppData);
        saveAppData(newAppData);
    }, [appData, setAppData]);

    const uploadImage = useCallback(async (imageId: string, file: File) => {
        const data = new FormData();
        data.append('imageId', imageId);
        data.append('file', file);

        const result: {fileName: string} = await (await fetch('/learner/upload', {
            method: 'POST',
            body: data
        })).json();

        return result.fileName;
    }, []);

    const saveStats = useCallback(async (stats: IGameStats) => {
        await fetch('/learner/stats', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(stats)
        });
    }, []);

    const getStats = useCallback(async () => {
        const result: IGameStats[] = await (await fetch('/learner/stats')).json();
        return result;
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const result = await (await fetch('/learner/appdata')).json();
            setAppData(result);
        };

        fetchData();
    }, []);

    return (
        <AppDataContext.Provider value={{
            appData,
            setStages,
            setActors,
            saveAppData: () => {
                saveAppData(appData);
            },
            uploadImage,
            saveStats,
            getStats
        }}>
            {children}
        </AppDataContext.Provider>
    );
};

export default AppDataProvider;
