import React from 'react';
import {IStage} from '../../Interfaces/stage.interface';
import StagePromptPlaceholder from '../StagePromptPlaceholder/StagePromptPlaceholder';
import './StagePromptEditor.scss';

export interface IStagePromptEditorProps {
    selectedStage: IStage;
}

function StagePromptEditor({selectedStage}: IStagePromptEditorProps) {
    return (
        <div className='stagePromptEditor'>
            {
                selectedStage.prompt.split('_').map((part, index) => {
                    return (
                        <div
                            key={`${selectedStage.id}_${index}`}
                            className='stagePromptEditorPart'
                        >
                            <div>{part}</div>
                            {
                                index < selectedStage.prompt.split('_').length - 1 &&
                                <StagePromptPlaceholder
                                    placeholderIndex={index}
                                    selectedStage={selectedStage}
                                />
                            }
                        </div>
                    );
                })
            }
        </div>
    );
}

export default StagePromptEditor;
