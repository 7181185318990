import React, {ReactNode, useCallback} from 'react';
import {IStageActor} from '../../Interfaces/stage.interface';
import './StageActorListItem.scss';
import {useDrag} from 'react-dnd';

export interface IStageActorListItemProps {
    actor: IStageActor;
}

const previewOptions = {
    offsetX: 0,
    offsetY: 0
};

function StageActorListItem({actor}: IStageActorListItemProps) {
    const [, drag, preview] = useDrag(() => ({
        type: 'Actor',
        item: actor,
        previewOptions
    }));

    const image = new Image();
    image.src = actor.imageUrl;

    preview(image, previewOptions);

    return (
        <div ref={drag} className='stageActorListItem'>
            <img
                className='stageActor'
                src={actor.imageUrl}
                alt='stage actor'
            />

            <div className='stageActorName'>{actor.name}</div>
        </div>
    );
}

export default StageActorListItem;
