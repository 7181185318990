import React from 'react';
import './StageListNewItem.scss';
import '../StageListItem/StageListItem.scss';

export interface IStageListNewItemProps {
    onAddNewStage: () => void;
}

function StageListNewItem(props: IStageListNewItemProps) {
    return (
        <div
            className='stageListItem stageListNewItem'
            onClick={() => props.onAddNewStage()}
        >
            +
        </div>
    );
}

export default StageListNewItem;
