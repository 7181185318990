import React, {useContext, useReducer} from 'react';
import './StagePromptPlaceholder.scss';
import {useDrop} from 'react-dnd';
import {IStage, IStageActorInstance} from '../../Interfaces/stage.interface';
import {AppDataContext} from '../../Providers/AppDataContext';

export interface IStagePromptPlaceholderProps {
    placeholderIndex: number;
    selectedStage: IStage;
}

function StagePromptPlaceholder({placeholderIndex, selectedStage}: IStagePromptPlaceholderProps) {
    const {saveAppData} = useContext(AppDataContext);

    const [, forceUpdate] = useReducer(x => x + 1, 0);

    const [, drop] = useDrop(() => ({
        accept: ['ActorInstance', 'HiddenActor'],
        drop: (item: IStageActorInstance, monitor) => {
            selectedStage.promptPlaceholders[placeholderIndex] = selectedStage.actorInstances?.indexOf(item)!;
            forceUpdate();
            saveAppData();
        }
    }), [selectedStage, placeholderIndex]);

    return (
        <div
            ref={drop}
            className='stagePromptPlaceholder'
        >
            {
                selectedStage.promptPlaceholders[placeholderIndex] != null &&
                selectedStage.actorInstances &&
                <img
                    src={selectedStage.actorInstances?.[selectedStage.promptPlaceholders[placeholderIndex]]?.actor.imageUrl ?? ''}
                    alt='Placeholder'
                />
            }
        </div>
    );
}

export default StagePromptPlaceholder;
