import React from 'react';
import './AppTitle.scss';

function AppTitle() {
    return (
        <div className='appTitleContent'>
            La famille Kasus
        </div>
    );
}

export default AppTitle;
