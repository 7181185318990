import React, {useCallback, useContext, useReducer} from 'react';
import './StageHiddenActorsList.scss';
import {IStage, IStageActor, IStageActorInstance} from '../../Interfaces/stage.interface';
import {useDrop} from 'react-dnd';
import {AppDataContext} from '../../Providers/AppDataContext';
import StageHiddenActor from '../StageHiddenActor/StageHiddenActor';
import PerfectScrollbar from 'react-perfect-scrollbar';

export interface IStageHiddenActorsListProps {
    selectedStage: IStage;
}

function StageHiddenActorsList({selectedStage}: IStageHiddenActorsListProps) {
    const {saveAppData} = useContext(AppDataContext);
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    const addActorToStage = useCallback((actor: IStageActor) => {
        if (!selectedStage.actorInstances) {
            selectedStage.actorInstances = [];
        }

        selectedStage.actorInstances = [
            ...selectedStage.actorInstances,
            {
                id: crypto.randomUUID(),
                actor,
                hidden: true
            }
        ];

        forceUpdate();
        saveAppData();
    }, [selectedStage]);

    const removeActorInstance = useCallback((actorInstance: IStageActorInstance) => {
        const index = selectedStage.actorInstances?.indexOf(actorInstance);
        if (index != null && index >= 0) {
            selectedStage.actorInstances?.splice(index, 1);
            forceUpdate();
            saveAppData();
        }
    }, [selectedStage]);

    const [, drop] = useDrop(() => ({
        accept: ['Actor'],
        drop: (item: IStageActor, monitor) => {
            addActorToStage(item as IStageActor);
        }
    }), [selectedStage]);

    return (
        <div
            className='stageHiddenActorsList'
            ref={drop}
        >
            <PerfectScrollbar>
                <div className='stageHiddenActorsList-container'>
                    {
                        selectedStage.actorInstances?.filter(actorInstance => actorInstance.hidden).map((actorInstance, index) => {
                            return <StageHiddenActor
                                key={`${selectedStage.id}_${actorInstance.id}`}
                                actorInstance={actorInstance}
                                onRemoveInstance={() => removeActorInstance(actorInstance)}
                            />;
                        })
                    }
                </div>
            </PerfectScrollbar>
        </div>
    );
}

export default StageHiddenActorsList;
