export enum StageType {
    FillInTheBlanks = 1,
    Choices = 2
}

export interface IStage {
    id: string;
    name: string;
    type: StageType;
    backgroundUrl?: string;
    actorInstances?: IStageActorInstance[];
    prompt: string;
    promptPlaceholders: Record<number, number>;
    choice0: string;
    choice0Hint: string;
    choice0Checked: boolean;
    choice1: string;
    choice1Hint: string;
    choice1Checked: boolean;
    choice2: string;
    choice2Hint: string;
    choice2Checked: boolean;
}

export interface IStageActor {
    id: string;
    name: string;
    imageUrl: string;
}

export interface IStageActorInstance {
    id: string;
    actor: IStageActor;
    position?: {x: number; y: number};
    hidden?: boolean;
}
