import React, {useCallback} from 'react';
import {IStageActorInstance} from '../../Interfaces/stage.interface';
import './StageActorInstance.scss';
import {useDrag} from 'react-dnd';

export interface IStageActorInstanceProps {
    actorInstance: IStageActorInstance;
    onRemoveInstance?: () => void;
    disableDrag?: boolean;
    answerPosition?: {x: number; y: number};
};

const previewOptions = {
    offsetX: 0,
    offsetY: 0
};

function StageActorInstance({actorInstance, onRemoveInstance, disableDrag, answerPosition}: IStageActorInstanceProps) {
    const [, drag, preview] = useDrag(() => ({
        type: 'ActorInstance',
        item: actorInstance,
        previewOptions,
        end: (item, monitor) => {
            if (!monitor.didDrop()) {
                onRemoveInstance?.();
            }
        },
        canDrag: () => {
            return disableDrag == null || !disableDrag;
        }
    }));

    const callbackRef = useCallback((node: any) => {
        drag(node);
        preview(node, previewOptions);
    }, [drag, preview]);

    return (
        <div
            ref={callbackRef}
            className='stageActorInstance'
            style={actorInstance.position && {
                left: answerPosition?.x ?? actorInstance.position.x,
                top: answerPosition?.y ?? actorInstance.position.y
            }}
        >
            <img src={actorInstance.actor.imageUrl} alt='Actor instance'/>
        </div>
    );
}

export default StageActorInstance;
