import React from 'react';
import './App.scss';
import 'react-perfect-scrollbar/dist/css/styles.css';
import AppTitle from './Components/AppTitle/AppTitle';
import {ThemeProvider} from '@emotion/react';
import {createTheme} from '@mui/material';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import AppDataProvider from './Providers/AppDataContext';
import AppRouter from './Components/AppRouter/AppRouter';

function App() {
    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        },
    });

    return (
        <ThemeProvider theme={darkTheme}>
            <DndProvider backend={HTML5Backend}>
                <AppDataProvider>
                    <div className='app'>
                        <div className='appTitle'>
                            <AppTitle />
                        </div>
                        <div className='appContent'>
                            <AppRouter />
                        </div>
                    </div>
                </AppDataProvider>
            </DndProvider>
        </ThemeProvider>
    );
}

export default App;
