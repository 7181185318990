import React, {useState} from 'react';
import StagesList from '../StagesList/StagesList';
import StageEditor from '../StageEditor/StageEditor';
import {IStage} from '../../Interfaces/stage.interface';

function AppEditor() {
    const [selectedStage, setSelectedStage] = useState<IStage>();

    return (
        <>
            <StagesList
                selectedStage={selectedStage}
                onStageSelected={stage => setSelectedStage(stage)}
            />
            <StageEditor
                selectedStage={selectedStage}
            />
        </>
    );
}

export default AppEditor;
