import React, {useReducer} from 'react';
import './GamePromptPlaceholder.scss';
import {useDrop} from 'react-dnd';
import {IStage, IStageActorInstance} from '../../Interfaces/stage.interface';

export interface IGamePromptPlaceholderProps {
    placeholderIndex: number;
    selectedStage: IStage;
    answer: Record<number, number>;
    onAnswerPlaced: (actorInstance: IStageActorInstance) => void;
}

function GamePromptPlaceholder({placeholderIndex, selectedStage, answer, onAnswerPlaced}: IGamePromptPlaceholderProps) {
    const [, drop] = useDrop(() => ({
        accept: ['GameActor'],
        drop: (item: IStageActorInstance, monitor) => {
            onAnswerPlaced(item);
        }
    }), [answer]);

    return (
        <div
            ref={drop}
            className='gamePromptPlaceholder'
        >
            {
                answer[placeholderIndex] != null &&
                selectedStage.actorInstances &&
                <img
                    src={selectedStage.actorInstances[answer[placeholderIndex]].actor.imageUrl}
                    alt='Placeholder'
                />
            }
        </div>
    );
}

export default GamePromptPlaceholder;
