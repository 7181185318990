import React, {useCallback, useContext, useState} from 'react';
import StageActorNewListItem from '../StageActorNewListItem/StageActorNewListItem';
import './StageActorsList.scss';
import {FileContent, SelectedFiles, useFilePicker} from 'use-file-picker';
import StageActorListItem from '../StageActorListItem/StageActorListItem';
import {AppDataContext} from '../../Providers/AppDataContext';
import PerfectScrollbar from 'react-perfect-scrollbar';

function StageActorsList() {
    const {appData: {actors}, setActors, uploadImage} = useContext(AppDataContext);

    const onAddNewActor = useCallback(async (file: File) => {
        const id = crypto.randomUUID();
        const fileName = await uploadImage(id, file);

        const newActor = {
            id,
            name: file.name,
            imageUrl: `/learner/upload/${fileName}`
        };

        setActors([...(actors || []), newActor]);
    }, [actors, setActors, uploadImage]);

    const [openFileSelector] = useFilePicker({
        accept: 'image/*',
        multiple: false,
        onFilesSelected: (selectedFiles: SelectedFiles) => {
            onAddNewActor(selectedFiles.plainFiles[0]);
        }
    });

    return (
        <PerfectScrollbar>
            <div className='stageActorsList'>
                {
                    actors?.map((actor, index) => {
                        return <StageActorListItem
                            key={index}
                            actor={actor}
                        />;
                    })
                }

                <StageActorNewListItem
                    onAddNewActor={openFileSelector}
                />
            </div>
        </PerfectScrollbar>
    );
}

export default StageActorsList;
