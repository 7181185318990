import React, {useCallback} from 'react';
import './StageHiddenActor.scss';
import {IStageActorInstance} from '../../Interfaces/stage.interface';
import {useDrag} from 'react-dnd';

export interface IStageActorInstanceProps {
    actorInstance: IStageActorInstance;
    onRemoveInstance?: () => void;
};

const previewOptions = {
    offsetX: 0,
    offsetY: 0
};

function StageHiddenActor({actorInstance, onRemoveInstance}: IStageActorInstanceProps) {
    const [, drag, preview] = useDrag(() => ({
        type: 'HiddenActor',
        item: actorInstance,
        previewOptions,
        end: (item, monitor) => {
            if (!monitor.didDrop()) {
                onRemoveInstance?.();
            }
        }
    }));

    const callbackRef = useCallback((node: any) => {
        drag(node);
        preview(node, previewOptions);
    }, [drag, preview]);

    return (
        <div
            ref={callbackRef}
            className='stageHiddenActor'
        >
            <img src={actorInstance.actor.imageUrl} alt='Hidden actor'/>
        </div>
    );
}

export default StageHiddenActor;
