import React from 'react';
import {IStage} from '../../Interfaces/stage.interface';
import './StageListItem.scss';

export interface IStageListItemProps {
    stage: IStage;
    isSelected: boolean;
    onSelectStage: () => void;
}

function StageListItem(props: IStageListItemProps) {
    return (
        <div
            className={`stageListItem ${props.isSelected ? 'stageListItem--selected' : ''}`}
            onClick={() => props.onSelectStage()}
        >
            {props.stage.name}
        </div>
    );
}

export default StageListItem;
