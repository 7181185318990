import React from 'react';
import {Route, Routes} from 'react-router-dom';
import AppEditor from '../AppEditor/AppEditor';
import Game from '../Game/Game';
import GameStats from '../GameStats/GameStats';

function AppRouter() {
    return (
        <>
            <Routes>
                <Route path="/" element={<Game />} />
                <Route path="/editor" element={<AppEditor />} />
                <Route path="/stats" element={<GameStats />} />
            </Routes>
        </>
    );
}

export default AppRouter;
